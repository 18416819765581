import React, { useState } from 'react';
import Layout from '../components/Layout';
import Callout from '../components/Callout';
import { Link } from 'gatsby';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Resource from '../components/Resource';
import Image from '../components/Image';
import Button from '../components/Button';
import Hero from '../components/Hero';
import videoHero from '../videos/Genentech-NeuroCerebell.mp4';
import Subfooter from '../components/Subfooter';
import '../styles/pages/_home.scss';
import imgGuidingPrinciples from '../images/img-home-guiding-principles.png';

const IndexPage = ({ data }) => {
  return (
    <Layout title="Home">
      <Hero
        className="mb-0"
        mediaSource={videoHero}
        mediaType={'video/mp4'}
        textOverlay={'Home Page'}
        mobileSrc={'header-mobile@2x.jpg'}
      >
        <div>
          <h1>Breaking DOWN BARRIERS IN BIOTECH EDUCATION</h1>
          <h3 className="home__hero-copy">
            Prepare learners for tomorrow’s biotechnology workforce through
            engaging instructional resources
          </h3>
        </div>
      </Hero>
      <Callout className="home__callout mb-3" to="/classroom-instruction">
        Explore the ways genetic inheritance shapes our daily lives in new
        lessons
      </Callout>
      <Section className="pb-0 pt-0">
        <Container>
          <Row>
            <Column fullWidth className="home__headline mb-2">
              <h2>
                The Futurelab+ ecosystem supports educators in three core areas:
              </h2>
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                img="img-home-classroom-resources.jpg"
                title="Comprehensive Curriculum"
                description="Written by and for teachers, the standards-aligned Futurelab+ curriculum showcases in-demand skills needed across the full product development lifecycle—from molecule to medicine—connecting students and educators to the breadth of education and career pathways across biotechnology."
                withBorder={false}
                asCard
                actions={[
                  {
                    label: 'Explore Lessons',
                    type: 'link',
                    path: '/classroom-instruction/',
                  },
                ]}
              />
            </Column>
            <Column size={4}>
              <Resource
                img="img-home-educators-action.jpg"
                title="Industry Connections"
                description="To expose learners to industry-wide applications of the Futurelab+ curriculum, the program connects teachers and their classrooms with biotechnology professionals— through on-demand career resources for students and virtual classroom visits."
                withBorder={false}
                asCard
                actions={[
                  {
                    label: 'GET CONNECTED',
                    type: 'link',
                    path: '/college-career-readiness/',
                  },
                ]}
              />
            </Column>
            <Column size={4}>
              <Resource
                className="mb-0 pb-0"
                img="img-home-college-career.jpg"
                title="Professional Development"
                description="Deepen your teaching practice by observing other educators facilitate empowering biotechnology lessons using a culturally competent lens."
                withBorder={false}
                asCard
                actions={[
                  {
                    label: 'LEARN MORE',
                    type: 'link',
                    path: '/educators-in-action/',
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pt-0">
        <Container>
          <Row className="home__equitably">
            <Column size={6} className="pl-0 pr-0">
              <Image filename="img-home-equitably.jpg" />
            </Column>
            <Column size={6}>
              <div className="home__equitably-text">
                <h3 className="home__bold-heading">
                  EQUITABLY EMPOWERING TOMORROW’S BIOTECH BREAKTHROUGHS
                </h3>
                <p>
                  <strong>Futurelab</strong>+ empowers and supports a community
                  of teachers to bring industry-aligned opportunities to all
                  students, providing greater and more equitable access to
                  explore and engage in a vast range of valuable science skills
                  and careers.
                </p>
                <Button to={'/classroom-instruction/instructional-support'}>
                  {'Learn More'}
                </Button>
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <Column size={5}>
              <div className="home__guiding-principles-text">
                <h3 className="home__bold-heading">GUIDING PRINCIPLES</h3>
                <p>
                  <strong>Futurelab</strong>+ is about the magic of discovery,
                  bringing the expertise of science professionals to students to
                  help them unleash their potential as the next generation of
                  innovators in the biotech industry.
                </p>
                <Link
                  to="/about/"
                  className="home__guiding-principles-learning-more"
                >
                  LEARN MORE
                </Link>
              </div>
            </Column>
            <Column size={7}>
              <img src={imgGuidingPrinciples} alt="Guiding Principles" />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0">
        <Subfooter content="Biotech is revolutionizing every aspect of life, from advances in medical diagnostics and drug development to improvements in agricultural and food products." />
      </Section>
    </Layout>
  );
};

export default IndexPage;

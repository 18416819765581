import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { Container } from '../Grid';
import Icon from '../Icon';
import './styles.scss';

const Callout = ({ to, children, className, ...other }) => {
  const renderLink = () => (
    <Link to={to} className="callout__link" target="_blank">
      {renderChildren()}
    </Link>
  );

  const renderChildren = () => (
    <Container className="callout__container" fullWidth>
      {children}
      {to && <Icon name="arrowright" className="callout__icon" />}
    </Container>
  );

  return (
    <aside className={classNames('callout', className)} {...other}>
      {to ? renderLink() : renderChildren()}
    </aside>
  );
};

Callout.defaultProps = {
  to: null,
};

Callout.propTypes = {
  /** A path to an internal page */
  to: PropTypes.string,
  /** A custom class name */
  className: PropTypes.string,
};

export default Callout;
